import React, { Suspense, lazy } from "react";

// import { Switch, Route } from "react-router-dom";
import { Routes, Route } from "react-router-dom";

import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "./config";
import { Styles } from "../styles/styles";

const Router = () => {
  return (
    <Suspense fallback={null}>
      <Styles />
      <Header />
      <Routes>
        {routes.map((routeItem) => {
          return (
            <Route
            key={routeItem.component}
            path={Array.isArray(routeItem.path) ? routeItem.path[0] : routeItem.path} 
            element={<Suspense fallback={<div>Loading...</div>}>
              {React.createElement(lazy(() => import(`../pages/${routeItem.component}`)))}
            </Suspense>}
          />
          
          );
        })}
    </Routes>

      <Footer />
    </Suspense>
  );
};

export default Router;
