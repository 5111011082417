// translation.ts
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

// Importaciones de archivos de traducción
import translationEn from "./locales/en/translation.json";
import translationEs from "./locales/es/translation.json";

// Lista de idiomas soportados
const SUPPORTED_LANGUAGES = ["en", "es"];

// Función para obtener el idioma preferido del navegador
export const getBrowserLanguage = (): string => {
  if (typeof window !== 'undefined' && window.navigator) {
    // Obtener el idioma del navegador
    const browserLang = navigator.language;
    const langPrefix = browserLang.split('-')[0]; // 'es-ES' -> 'es'
    
    // Verificar si el idioma está soportado
    if (SUPPORTED_LANGUAGES.includes(langPrefix)) {
      return langPrefix;
    }
  }
  
  return "en"; // Idioma por defecto si no hay coincidencia
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    
    // Importante: NO establecer un lng por defecto aquí
    // para permitir que el detector funcione primero
    fallbackLng: "en",
    
    // Configuración del detector de idioma - Cambiado el orden para priorizar navigator
    detection: {
      // Primero navigator (navegador), después localStorage, cookie, etc.
      order: ["navigator", "localStorage", "cookie", "querystring", "htmlTag"],
      lookupQuerystring: "lng", // Parámetro de URL para cambiar idioma (?lng=es)
      lookupCookie: "i18nextLng",
      lookupLocalStorage: "i18nextLng",
      caches: ["localStorage", "cookie"], // Guardar en localStorage y cookie
    },
    
    keySeparator: false,
    interpolation: {
      escapeValue: false, // Útil para React - evita escapar HTML
    },
    
    // Recursos de traducción
    resources: {
      en: { translations: translationEn },
      es: { translations: translationEs },
    },
    
    // Configuración adicional
    ns: ["translations"],
    defaultNS: "translations",
    supportedLngs: SUPPORTED_LANGUAGES,
    
    // Opciones específicas para React
    react: {
      useSuspense: true,
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    }
  });

// Función para establecer el idioma inicial si no hay uno guardado
export const initializeLanguage = (): void => {
  // Verificamos si es un entorno con window
  if (typeof window === 'undefined') return;
  
  // Solo establecer si no hay un idioma guardado previamente
  if (!localStorage.getItem("i18nextLng")) {
    const detectedLang = getBrowserLanguage();
    console.log("Idioma detectado del navegador:", detectedLang);
    i18n.changeLanguage(detectedLang);
  } else {
    console.log("Idioma guardado en localStorage:", localStorage.getItem("i18nextLng"));
  }
};

// Establece el atributo lang en el documento HTML para accesibilidad
const setDocumentLang = (lng: string): void => {
  if (typeof document !== 'undefined') {
    document.documentElement.setAttribute('lang', lng);
    console.log("Atributo lang establecido a:", lng);
  }
};

// Establece el idioma inicial solo en cliente
if (typeof window !== 'undefined') {
  // Establece el idioma inicial
  setDocumentLang(i18n.language);

  // Actualiza el atributo lang cuando cambia el idioma
  i18n.on('languageChanged', (lng: string) => {
    setDocumentLang(lng);
    console.log("Idioma cambiado a:", lng);
  });
}

export default i18n;

// // translation.ts
// import i18n from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
// import Backend from "i18next-xhr-backend";
// import { initReactI18next } from "react-i18next";

// // Importaciones de archivos de traducción
// import translationEn from "./locales/en/translation.json";
// import translationEs from "./locales/es/translation.json";

// // Lista de idiomas soportados
// const SUPPORTED_LANGUAGES = ["en", "es"];

// // Función para obtener el idioma preferido del navegador
// export const getBrowserLanguage = (): string => {
//   // Obtener el idioma del navegador
//   const browserLang = navigator.language;
//   const langPrefix = browserLang.split('-')[0]; // 'es-ES' -> 'es'
  
//   // Verificar si el idioma está soportado
//   if (SUPPORTED_LANGUAGES.includes(langPrefix)) {
//     return langPrefix;
//   }
  
//   return "en"; // Idioma por defecto si no hay coincidencia
// };

// i18n
//   .use(Backend)
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     debug: process.env.NODE_ENV === 'development',
//     fallbackLng: "en",
    
//     // Configuración del detector de idioma
//     detection: {
//       // Orden de detección: primero URL, luego cookie, localStorage, navegador y finalmente el HTML
//       order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag"],
//       lookupQuerystring: "lng", // Parámetro de URL para cambiar idioma (?lng=es)
//       lookupCookie: "i18nextLng",
//       lookupLocalStorage: "i18nextLng",
//       caches: ["localStorage", "cookie"], // Guardar en localStorage y cookie
//     },
    
//     keySeparator: false,
//     interpolation: {
//       escapeValue: false, // Útil para React - evita escapar HTML
//     },
    
//     // Recursos de traducción
//     resources: {
//       en: { translations: translationEn },
//       es: { translations: translationEs },
//     },
    
//     // Configuración adicional
//     ns: ["translations"],
//     defaultNS: "translations",
//     supportedLngs: SUPPORTED_LANGUAGES,
    
//     // Opciones específicas para React
//     react: {
//       useSuspense: true,
//       bindI18n: 'languageChanged',
//       bindI18nStore: '',
//       transEmptyNodeValue: '',
//       transSupportBasicHtmlNodes: true,
//       transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
//     }
//   });

// // Función para establecer el idioma inicial si no hay uno guardado
// export const initializeLanguage = (): void => {
//   // Solo establecer si no hay un idioma guardado previamente
//   if (!localStorage.getItem("i18nextLng")) {
//     const detectedLang = getBrowserLanguage();
//     i18n.changeLanguage(detectedLang);
//   }
// };

// // Establece el atributo lang en el documento HTML para accesibilidad
// const setDocumentLang = (lng: string): void => {
//   document.documentElement.setAttribute('lang', lng);
// };

// // Establece el idioma inicial
// setDocumentLang(i18n.language);

// // Actualiza el atributo lang cuando cambia el idioma
// i18n.on('languageChanged', (lng: string) => {
//   setDocumentLang(lng);
// });

// // Inicializar el idioma automáticamente
// initializeLanguage();

// export default i18n;
