// index.tsx
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.css";

import Router from "./router";
import i18n, { initializeLanguage } from "./translation";

const App = () => {
  // Inicializar el idioma cuando se monta el componente
  useEffect(() => {
    // Mostrar el idioma actual del navegador para depuración
    console.log("Idioma del navegador:", navigator.language);
    
    // Inicializar el idioma basado en el navegador
    initializeLanguage();
    
    // Mostrar el idioma seleccionado después de la inicialización
    console.log("Idioma establecido en i18n:", i18n.language);
  }, []);

  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <Router />
      </I18nextProvider>
    </BrowserRouter>
  );
};

// Usa `createRoot` en lugar de `ReactDOM.render`
const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
// import React, { useEffect } from "react";
// import ReactDOM from "react-dom/client"; // 👈 Importa desde "react-dom/client"
// import { BrowserRouter } from "react-router-dom";
// import { I18nextProvider } from "react-i18next";
// import "antd/dist/antd.css";

// import Router from "./router";
// import i18n, { initializeLanguage } from "./translation";

// const App = () => {
//   // Inicializar el idioma cuando se monta el componente
//   useEffect(() => {
//     initializeLanguage();
//   }, []);

//   return (
//     <BrowserRouter>
//       <I18nextProvider i18n={i18n}>
//         <Router />
//       </I18nextProvider>
//     </BrowserRouter>
//   );
// };
// // 🔹 Usa `createRoot` en lugar de `ReactDOM.render`
// const root = ReactDOM.createRoot(document.getElementById("root")!);
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
